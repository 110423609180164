'use strict'

$(window).on('load', function () {
  $("html").addClass("loaded")
})

$('*[data-aria-controls]').on('click', function () {
  $('[aria-controls="' + $(this).data('aria-controls') + '"]').click()
})

$('*[aria-controls][aria-expanded]').on('click', function () {
  const $control = $(this)
  const $target = $('#' + $control.attr('aria-controls'))

  if ($control.attr('aria-expanded') === 'true') {
    $control.attr('aria-expanded', 'false')
    $target.toggleClass('is-expanded', false)
  }
  else {
    $control.attr('aria-expanded', 'true')
    $target.toggleClass('is-expanded', true)
  }
})

// DelayedLink
if (window.matchMedia("(pointer: coarse)").matches)
{
  window.activeLink = null

  $(".DelayedLink").on("mouseenter", _ => {
    window.activeLink = this
  })
  .on("click", e => {
    if (window.activeLink == this) {
      e.preventDefault()
      window.activeLink = null
    }
  })
}


$("#Email").on("mouseover", function () {
  $(this).text(["general", ["waveharmonic", "com"].join(".")].join("@"))
})
